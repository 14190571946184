import React, {useEffect} from 'react';
import '../styles/LinkToStore.scss';
const LinkToStore: React.FC = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  const handleDownload = () => {
    if (/android/i.test(userAgent)) {
      // Android 기기일 경우 Google Play Store로 리디렉션
      window.location.href = 'https://play.google.com/store/apps/details?id=com.eqbr.memble.app';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      // iOS 기기일 경우 Apple App Store로 리디렉션
      window.location.href = 'https://apps.apple.com/kr/app/memble/id6670382910';
    } else {
      // 그 외의 경우 (예: PC 또는 다른 기기)
      alert('This link is optimized for mobile devices.');
    }
  };

  useEffect(() => {
    if(/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)){
      handleDownload();
    }
    else{
      alert('이 링크는 모바일에서만 작동합니다.\nThis link only works on mobile.');
    }
  }, []);

  return (
      <div id={"LinkToStore"}>
        <div className='welcome-text'>Welcome To Memble!</div>
        <div className='welcome-text'>멤블에 오신 것을 환영합니다!</div>
      </div>
  );
};

export default LinkToStore;